import React, { useState, useEffect, useMemo } from "react";
import { Route, Switch, Redirect, useLocation, useHistory } from "react-router-dom";
import jsCookie from "js-cookie";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";
import VuiBox from "components/VuiBox";
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";
import theme from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import routes from "routes";
import { useVisionUIController, setMiniSidenav, setOpenConfigurator } from "context";
import Authenticator from "./Authenticator";
import { ToastProvider } from 'react-toast-notifications';
import { UserProvider } from './UserContext';
import toast from "react-simple-toasts";
import SignIn from './layouts/authentication/sign-in/index';

export default function App() {
  const [controller, dispatch] = useVisionUIController();
  const { miniSidenav, direction, layout, openConfigurator, sidenavColor } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();
  const isAuthenticated = !!jsCookie.get('token');
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [token, setToken] = useState(null);
  const [user, setUser] = useState(null);

  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  useEffect(() => {
    const fetchUserData = () => {
      fetch('https://thc.bnbempresta.fun/user', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + jsCookie.get('token'),
        },
      })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === 'error') {
          toast(res.message, 'error');
          setLoading(false);
          history.push('/authentication/sign-in');
        } else if (res.status === 'success') {
          setUser(res.data);
          setLoading(false);
        } else {
          toast('Erro ao conectar com o servidor!', 'error');
          setLoading(false);
          history.push('/authentication/sign-in');
        }
      })
      .catch(() => {
        toast('Erro ao conectar com o servidor!', 'error');
        setLoading(false);
        history.push('/authentication/sign-in');
      });
    };

    if (!jsCookie.get('token')) {
      setLoading(false);
      history.push('/authentication/sign-in');
      return;
    }

    fetchUserData();

    const intervalId = setInterval(fetchUserData, 9000);

    return () => clearInterval(intervalId);
  }, [history]);

  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        if (route.protected && !token) {
          return <Redirect to="/authentication/sign-in" key={route.key} />;
        }
        return <Route exact path={route.route} component={route.component} key={route.key} />;
      }

      return null;
    });

  const configsButton = (
    <VuiBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.5rem"
      height="3.5rem"
      bgColor="info"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="white"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="default" color="inherit">
        settings
      </Icon>
    </VuiBox>
  );

  return (
    <UserProvider>
      <ToastProvider>
        {direction === "rtl" ? (
          <CacheProvider value={rtlCache}>
            <ThemeProvider theme={themeRTL}>
              <CssBaseline />
              {isAuthenticated ? (
                <>
                  {layout === "dashboard" && (
                    <>
                      <Sidenav
                        color={sidenavColor}
                        brand=""
                        brandName="power"
                        routes={routes}
                        onMouseEnter={handleOnMouseEnter}
                        onMouseLeave={handleOnMouseLeave}
                      />
                      <Configurator />
                      {configsButton}
                    </>
                  )}
                  {layout === "vr" && <Configurator />}
                  <Switch>
                    {getRoutes(routes)}
                    <Redirect from="*" to="/dashboard" />
                  </Switch>
                </>
              ) : (
                <Switch>
                  <Route path="/authentication/sign-in" component={SignIn} />
                  <Redirect from="*" to="/authentication/sign-in" />
                </Switch>
              )}
            </ThemeProvider>
          </CacheProvider>
        ) : (
          <ThemeProvider theme={theme}>
            <CssBaseline />
            {isAuthenticated ? (
              <>
                {layout === "dashboard" && (
                  <>
                    <Sidenav
                      color={sidenavColor}
                      brand=""
                      brandName="power"
                      routes={routes}
                      onMouseEnter={handleOnMouseEnter}
                      onMouseLeave={handleOnMouseLeave}
                    />
                    <Configurator />
                    {configsButton}
                  </>
                )}
                {layout === "vr" && <Configurator />}
                <Switch>
                  {getRoutes(routes)}
                  <Redirect from="*" to="/dashboard" />
                </Switch>
              </>
            ) : (
              <Switch>
                <Route path="/authentication/sign-in" component={SignIn} />
                <Redirect from="*" to="/authentication/sign-in" />
              </Switch>
            )}
          </ThemeProvider>
        )}
      </ToastProvider>
    </UserProvider>
  );
}
