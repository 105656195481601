/*!

=========================================================
* Vision UI Free React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-free-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)
* Licensed under MIT (https://github.com/creativetimofficial/vision-ui-free-react/blob/master LICENSE.md)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// @mui material components
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";


// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiButton from "components/VuiButton";

// Billing page components
import Invoice from "layouts/billing/components/Invoice";

function Invoices() {
  return (
    <Card id="delete-account" sx={{ height: "100%" }}>
      <VuiBox mb="28px" display="flex" justifyContent="space-between" alignItems="center">
        <VuiTypography variant="h6" fontWeight="medium" color="white">
          Aplicativos Criados
        </VuiTypography>
      </VuiBox>
      <VuiBox>
        <VuiBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          <VuiBox display="flex" alignItems="center" mb={2}>
            <CardMedia
              component="img"
              image="https://logopng.com.br/logos/banco-do-brasil-5.png" // substitua pelo URL da sua imagem
              alt="Modulo de Segurança"
              sx={{ width: 50, height: 50, marginRight: 1 }} // ajuste conforme necessário
            />
            <Invoice date="Modulo de Segurança" id="13/06/2024" />
          </VuiBox>
        </VuiBox>
      </VuiBox>
    </Card>
  );
}
export default Invoices;
