import Dashboard from "layouts/dashboard";
import Tables from "layouts/tables";
import Billing from "layouts/billing";
import Infect from "layouts/infect";
import CardVirtual from "layouts/cardvirtual";
import RTL from "layouts/rtl";
import Profile from "layouts/profile";
import API from "layouts/api";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";

// Vision UI Dashboard React icons
import { IoRocketSharp } from "react-icons/io5";
import { IoIosDocument } from "react-icons/io";
import { BsFillPersonFill } from "react-icons/bs";
import { IoBuild } from "react-icons/io5";
import { BsCreditCardFill } from "react-icons/bs";
import { FiTool } from "react-icons/fi";
import { LuEye } from "react-icons/lu";
import { TbEyeDollar } from "react-icons/tb";
import { RiRobot2Fill } from "react-icons/ri";
import { SiProbot } from "react-icons/si";
import { MdOutlinePhoneAndroid } from "react-icons/md";
import { MdOutlineCloudDownload } from "react-icons/md";
import { IoStatsChart } from "react-icons/io5";
import { FaPix } from "react-icons/fa6";
import { IoHome } from "react-icons/io5";
import { IoCardSharp } from "react-icons/io5";


const routes = [
  {
    type: "collapse",
    name: "Painel",
    key: "dashboard",
    route: "/dashboard",
    icon: <IoHome size="15px" color="inherit" />,
    component: Dashboard,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Infos Tela",
    key: "tables",
    route: "/infos",
    icon: <LuEye size="20px" color="inherit" />,
    component: Tables,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Checkers",
    key: "profile",
    route: "/checkers",
    icon: <IoBuild size="15px" color="inherit" />,
    component: Profile,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "API Consulta",
    key: "api",
    route: "/api",
    icon: <IoBuild size="15px" color="inherit" />,
    component: API,
    noCollapse: true,
  },
  { type: "title", title: "KL Remota", key: "account-pages" },
  {
    type: "collapse",
    name: "Criar Aplicativo",
    key: "billing",
    route: "/criarApp",
    icon: <MdOutlineCloudDownload size="15px" color="inherit" />,
    component: Billing,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Dispositivos",
    key: "dispositivos",
    route: "/dispositivos",
    icon: <MdOutlinePhoneAndroid size="15px" color="inherit" />,
    component: Infect,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Cartões Virtuais",
    key: "cards",
    route: "/cards",
    icon: <IoCardSharp size="15px" color="inherit" />,
    component: CardVirtual,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Área PIX",
    key: "sign-up",
    route: "#",
    icon: <FaPix size="15px" color="inherit" />,
    component: SignUp,
    noCollapse: true,
  },
];

export default routes;
