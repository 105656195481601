import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import jsCookie from "js-cookie";

const Authenticator = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    // Verifica se há um token de autenticação
    const token = jsCookie.get('token');
    setIsAuthenticated(!!token);
  }, []);

  // Se não estiver autenticado, redireciona para a página de login
  if (!isAuthenticated) {
    return <Redirect to="/authentication/sign-in" />;
  }

  // Se estiver autenticado, renderiza o conteúdo filho
  return <>{children(isAuthenticated)}</>;
};

export default Authenticator;